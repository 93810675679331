import React, {
  useState,
  useCallback,
  useMemo,
  useContext,
  useEffect,
} from 'react';
import useHomePageData from 'components/pages/homepage/hooks';

import {
  getBriefItems,
  getRandomizedBriefs,
  getItemParameters,
  checkStep1ForChanges,
  getRandomElement,
  numberWithCommas,
  getPossibilitiesCount,
} from 'components/pages/homepage/utils';
import { BriefListItem } from 'components/organisms/BriefList/types';
import shuffle from 'lodash/shuffle';
import { BriefContext } from './context';
import { PresetsContext } from '../PresetsProvider/context';

export const MAX_BRIEF_ITEMS_DISPLAYED = 5;

const BriefContextProvider: React.FC = ({ children }) => {
  const { resetPreset } = useContext(PresetsContext);
  const { categories: allCategories } = useHomePageData();
  const [isGeneratedBrief, setIsGeneratedBrief] = useState(false);

  const [activeCategories, setActiveCategories] = useState(
    allCategories.filter((category) => category.selected),
  );
  const [briefItems, setBriefItems] = useState<BriefListItem[]>([]);

  const activeCategoriesIds = useMemo(
    () => Object.values(activeCategories).map((category) => category.id),
    [activeCategories],
  );

  const possibilities = useMemo(
    () => numberWithCommas(getPossibilitiesCount(activeCategories)),
    [activeCategories],
  );

  const toggleDisabled = useCallback(
    (id) => {
      setBriefItems((prev) =>
        prev.map((item) => ({
          ...item,
          disabled: item.id === id ? !item.disabled : item.disabled,
        })),
      );
      resetPreset();
    },
    [resetPreset],
  );

  const removeCategory = useCallback(
    (id) => {
      if (activeCategories.length <= 1) return;

      setActiveCategories((prev) => prev.filter((item) => item.id !== id));

      if (isGeneratedBrief) {
        setBriefItems((prev) => prev.filter((item) => item.id !== id));
      }

      resetPreset();
    },
    [activeCategories.length, isGeneratedBrief, resetPreset],
  );

  const addCategory = useCallback(() => {
    if (
      activeCategories.length >= MAX_BRIEF_ITEMS_DISPLAYED &&
      briefItems.length >= MAX_BRIEF_ITEMS_DISPLAYED
    )
      return;

    const inActiveCategories = allCategories.filter(
      ({ id }) => !activeCategoriesIds.includes(id),
    );

    const newRandomElement = getRandomElement(inActiveCategories);

    setActiveCategories((prev) => [...prev, newRandomElement]);

    if (isGeneratedBrief) {
      const newBriefItem = getBriefItems([newRandomElement], true, true)[0];

      setBriefItems((prev) => [...prev, newBriefItem]);
    }

    resetPreset();
  }, [
    activeCategories.length,
    briefItems.length,
    allCategories,
    isGeneratedBrief,
    resetPreset,
    activeCategoriesIds,
  ]);

  const toggleActiveCategories = useCallback(
    (oldKey, newKey, setCategory = false) => {
      setActiveCategories((prev) => {
        let newItem = allCategories.find((category) => category.id === newKey);

        if (setCategory && newItem) {
          newItem = { ...newItem, category: getRandomElement(newItem.items) };
        }

        return prev.map((item) => (item.id === oldKey ? newItem : item));
      });
      resetPreset();
    },
    [allCategories, resetPreset],
  );

  const setNewCategories = useCallback(
    (categoriesList?: string[]) => {
      if (categoriesList?.length) {
        const newCategories = allCategories.filter((category) =>
          categoriesList.includes(category.id),
        );
        if (
          isGeneratedBrief &&
          checkStep1ForChanges(briefItems, activeCategoriesIds)
        ) {
          const newItems = getBriefItems(newCategories);

          setBriefItems(newItems);
        }
        setActiveCategories(newCategories);
      }
    },
    [activeCategoriesIds, allCategories, briefItems, isGeneratedBrief],
  );

  const changeBriefItem = useCallback(
    (oldId, newId) => {
      const newItem = getBriefItems(
        [allCategories.find((category) => category.id === newId)],
        true,
        true,
      )[0];

      setBriefItems((prev) =>
        prev.map((item) => (item.id === oldId ? newItem : item)),
      );
      toggleActiveCategories(oldId, newId, true);
    },
    [allCategories, toggleActiveCategories],
  );

  const reloadParameters = useCallback(
    (id) => {
      setBriefItems((prev) =>
        prev.map(({ items, ...item }) => ({
          ...item,
          items: item.id === id ? shuffle(items) : items,
          category:
            item.id === id
              ? getRandomElement(getItemParameters(item.id, activeCategories))
              : item.category,
        })),
      );
    },
    [activeCategories],
  );

  const generateBrief = useCallback(() => {
    setIsGeneratedBrief(true);

    setBriefItems((prevBriefs) =>
      getRandomizedBriefs(prevBriefs, activeCategories),
    );
  }, [activeCategories]);

  useEffect(() => {
    if (!isGeneratedBrief)
      setBriefItems(getBriefItems(activeCategories, false));
  }, [activeCategories, isGeneratedBrief]);

  const context = useMemo(
    () => ({
      possibilities,
      categories: allCategories,
      activeCategories,
      activeCategoriesIds,
      briefItems,
      setNewCategories,
      setBriefItems,
      toggleActiveCategories,
      toggleDisabled,
      removeCategory,
      addCategory,
      changeBriefItem,
      reloadParameters,
      generateBrief,
      setActiveCategories,
      isGeneratedBrief,
    }),
    [
      activeCategories,
      activeCategoriesIds,
      allCategories,
      possibilities,
      briefItems,
      setNewCategories,
      setBriefItems,
      toggleActiveCategories,
      toggleDisabled,
      removeCategory,
      addCategory,
      changeBriefItem,
      reloadParameters,
      generateBrief,
      setActiveCategories,
      isGeneratedBrief,
    ],
  );

  return (
    <BriefContext.Provider value={context}>{children}</BriefContext.Provider>
  );
};

export default BriefContextProvider;
