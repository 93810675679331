/** @jsx jsx */
import { jsx, Flex, Container, Link } from 'theme-ui';

import Inner from 'components/styles/Inner';
import Preset from 'components/molecules/Preset';
import { ToolsProps } from './types';

const Tools: React.FC<ToolsProps> = ({ label, href, onChangePreset }) => (
  <Container>
    <Inner>
      <Flex variant="tools">
        <Preset {...{ onChangePreset }} />
        <Link
          {...{ href }}
          target="_blank"
          rel="noreferrer"
          variant="tools.link"
        >
          {label}
        </Link>
      </Flex>
    </Inner>
  </Container>
);

export default Tools;
