/** @jsx jsx */
import { jsx, Box } from 'theme-ui';

import Inner from 'components/styles/Inner';
import { SectionProps } from './types';

const Section: React.FC<SectionProps> = ({ heading, children }) => (
  <Box as="section" variant="section.wrapper">
    <Inner>
      <Box variant="section.content">
        <Box variant="section.heading">{heading}</Box>
        {children}
      </Box>
    </Inner>
  </Box>
);

export default Section;
