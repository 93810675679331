/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import noop from 'lodash/noop';
import { motion } from 'framer-motion';

import {
  IconLocked,
  IconLockedEmpty,
  IconMinus,
  IconRandomize,
} from 'assets/svg';
import IconButton from 'components/atoms/Buttons/IconButton';
import Select from 'components/atoms/Fields/Select';
import { FieldSize } from 'components/atoms/Fields/types';
import useToggle from 'hooks/useToggle';
import { BriefContext } from '../../../../../Providers/BriefProvider/context';
import { ParametersSelectProps } from './types';
import { listAnimationVariant, listItemAnimationVariant } from '../../consts';

const ParametersSelect: React.FC<ParametersSelectProps> = ({
  id,
  disabled = false,
  onRandomizeClick = noop,
}) => {
  const [isLocked, { toggle }] = useToggle();

  const {
    categories,
    activeCategoriesIds,
    toggleDisabled,
    reloadParameters,
    changeBriefItem,
    removeCategory,
  } = useContext(BriefContext);

  const defaultValue = useMemo(
    () => categories.find((option) => option.id === id),
    [categories, id],
  );

  const getOptionValue = useCallback(({ id: optionId }) => optionId, []);
  const getOptionLabel = useCallback(({ name: optionName }) => optionName, []);
  const checkIfOptionIsDisabled = useCallback(
    ({ id: optionId }) => activeCategoriesIds.includes(optionId),
    [activeCategoriesIds],
  );

  const handleOnChange = useCallback(
    ({ id: newId }) => changeBriefItem(id, newId),
    [id, changeBriefItem],
  );

  const handleToggleClick = useCallback(() => {
    toggle();
    toggleDisabled(id);
  }, [id, toggle, toggleDisabled]);

  const randomizeClick = useCallback(() => {
    reloadParameters(id);
    onRandomizeClick();
  }, [id, onRandomizeClick, reloadParameters]);

  const handleRemoveBriefElement = useCallback(() => {
    removeCategory(id);
  }, [id, removeCategory]);

  useEffect(() => {
    // reset variable "isLocked" after generate another brief with changes in step 1
    if (disabled !== isLocked) toggle();
  }, [disabled, isLocked, toggle]);

  const commonStyle = {
    'button, label': {
      opacity: isLocked ? 0.5 : undefined,
      variant: 'transitions.default',
    },
  };

  return (
    <motion.div
      sx={{ variant: 'briefList.brief.select' }}
      variants={listAnimationVariant}
      initial="hidden"
      animate="visible"
      layout
    >
      <motion.div
        variants={listItemAnimationVariant}
        layout
        className="remove-button-desktop"
        sx={{
          ...commonStyle,
          variant: 'briefList.brief.selectItem',
          pointerEvents: isLocked ? 'none' : undefined,
        }}
      >
        <IconButton
          aria-label="Remove"
          type="button"
          onClick={handleRemoveBriefElement}
        >
          <IconMinus />
        </IconButton>
      </motion.div>
      <motion.div
        variants={listItemAnimationVariant}
        layout
        sx={{
          ...commonStyle,
          variant: 'briefList.brief.selectItem',
          pointerEvents: isLocked ? 'none' : undefined,
        }}
      >
        <Select
          size={FieldSize.small}
          options={categories}
          isOptionDisabled={checkIfOptionIsDisabled}
          onChange={handleOnChange}
          {...{ defaultValue, getOptionLabel, getOptionValue }}
        />
      </motion.div>
      <motion.div
        variants={listItemAnimationVariant}
        layout
        sx={{
          ...commonStyle,
          variant: 'briefList.brief.selectItem',
          pointerEvents: isLocked ? 'none' : undefined,
        }}
      >
        <IconButton
          aria-label="Randomize"
          type="button"
          onClick={randomizeClick}
        >
          <IconRandomize />
        </IconButton>
      </motion.div>
      <motion.div
        sx={{ variant: 'briefList.brief.selectItem' }}
        variants={listItemAnimationVariant}
        layout
      >
        <IconButton
          aria-label={isLocked ? 'Unlock' : 'Lock'}
          type="button"
          onClick={handleToggleClick}
        >
          {isLocked ? (
            <IconLocked />
          ) : (
            <IconLockedEmpty sx={{ opacity: 0.2 }} />
          )}
        </IconButton>
      </motion.div>
    </motion.div>
  );
};

export default ParametersSelect;
