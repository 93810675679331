/** @jsx jsx */
import { jsx, Text } from 'theme-ui';

import { LabelProps } from './types';

const Label: React.FC<LabelProps> = ({ label }) => (
  <Text as="p" variant="typo3" color="grayChateau">
    {label}
  </Text>
);

export default Label;
