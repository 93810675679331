/** @jsx jsx */
import { useCallback, useContext, useMemo } from 'react';
import { jsx, Box, Flex } from 'theme-ui';
import { motion } from 'framer-motion';

import { IconMinus } from 'assets/svg';
import IconButton from 'components/atoms/Buttons/IconButton';
import Select from 'components/atoms/Fields/Select';
import { BriefContext } from '../../../../../Providers/BriefProvider/context';
import { CategorySelectProps } from './types';
import { listAnimationVariant, listItemAnimationVariant } from '../../consts';

const CategorySelect: React.FC<CategorySelectProps> = ({
  id,
  categoryExample,
}) => {
  const {
    categories,
    activeCategoriesIds,
    toggleActiveCategories,
    removeCategory,
  } = useContext(BriefContext);

  const getOptionLabel = useCallback(({ name: optionName }) => optionName, []);
  const checkIfOptionIsDisabled = useCallback(
    ({ id: optionId }) => activeCategoriesIds.includes(optionId),
    [activeCategoriesIds],
  );
  const getOptionValue = useCallback(({ id: optionId }) => optionId, []);
  const handleOnChange = useCallback(
    ({ id: newId }) => toggleActiveCategories(id, newId),
    [id, toggleActiveCategories],
  );

  const defaultValue = useMemo(
    () => categories.find((option) => option.id === id),
    [categories, id],
  );

  const handleRemoveCategory = useCallback(() => {
    removeCategory(id);
  }, [id, removeCategory]);

  const commonProps = {
    defaultValue,
    getOptionLabel,
    options: categories,
    isOptionDisabled: checkIfOptionIsDisabled,
    getOptionValue,
    onChange: handleOnChange,
  };

  return (
    <Box variant="briefList.parameters.wrapper">
      <Box variant="briefList.parameters.desktopToolsWrapper">
        <motion.div
          variants={listAnimationVariant}
          initial="hidden"
          animate="visible"
          layout
        >
          <motion.div
            sx={{ variant: 'briefList.parameters.button' }}
            variants={listItemAnimationVariant}
            layout
          >
            <IconButton
              aria-label="Remove"
              type="button"
              onClick={handleRemoveCategory}
              className="remove-button-desktop"
            >
              <IconMinus />
            </IconButton>
          </motion.div>
          <motion.div
            sx={{ variant: 'briefList.parameters.select' }}
            variants={listItemAnimationVariant}
            layout
          >
            <Select {...commonProps} />
          </motion.div>
        </motion.div>
      </Box>
      <Box variant="briefList.parameters.mobileToolsWrapper">
        <Flex variant="briefList.parameters.mobileTools">
          <Select
            {...commonProps}
            label={categoryExample ? `e.g. ${categoryExample}` : undefined}
          />
          <Box variant="briefList.parameters.button">
            <IconButton
              aria-label="Remove"
              type="button"
              onClick={handleRemoveCategory}
              className="remove-button"
            >
              <IconMinus />
            </IconButton>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default CategorySelect;
