import { useState, useCallback, useEffect } from 'react';
import pullAt from 'lodash/pullAt';

interface RefRegistryEntry {
  element: HTMLElement;
  toggleOff: VoidFunction;
  toggleState: boolean;
}

export type UseToggleReturnType = [
  boolean,
  {
    toggle: VoidFunction;
    toggleOn: VoidFunction;
    toggleOff: VoidFunction;
    registerContainerRef: (ref: HTMLElement | null) => void;
  },
];

let documentClickHandlerRegistered = false;
const refsRegistry: RefRegistryEntry[] = [];

const documentClickHandler = (event: MouseEvent): void => {
  refsRegistry.forEach(({ element, toggleOff, toggleState }) => {
    if (toggleState && !element.contains(event.target as Node)) {
      toggleOff();
    }
  });
};

const useToggle = ({
  defaultToggleState = false,
} = {}): UseToggleReturnType => {
  const [toggleState, setToggleState] = useState(defaultToggleState);

  const toggle = useCallback(() => {
    setToggleState((currentToggleState) => !currentToggleState);
  }, []);

  const toggleOff = useCallback(() => {
    setToggleState(false);
  }, []);

  const toggleOn = useCallback(() => {
    setToggleState(true);
  }, []);

  useEffect(() => {
    if (!documentClickHandlerRegistered) {
      documentClickHandlerRegistered = true;
      document.addEventListener('click', documentClickHandler);
    }

    return () => {
      if (!documentClickHandlerRegistered) {
        document.removeEventListener('click', documentClickHandler);
      }
    };
  }, []);

  const registerContainerRef = useCallback(
    (ref: HTMLElement | null): void => {
      const currentEntryIndex = refsRegistry.findIndex(
        ({ toggleOff: entryToggleOff }) => entryToggleOff === toggleOff,
      );

      if (!ref && currentEntryIndex >= 0) {
        pullAt(refsRegistry, currentEntryIndex);

        return;
      }

      if (ref && currentEntryIndex < 0) {
        refsRegistry.push({
          element: ref,
          toggleOff,
          toggleState,
        });
      }
    },
    [toggleOff, toggleState],
  );

  return [toggleState, { toggle, toggleOn, toggleOff, registerContainerRef }];
};

export default useToggle;
