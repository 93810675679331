export const listAnimationVariant = {
  hidden: {
    opacity: 1,
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.05,
      staggerChildren: 0.08,
    },
  },
};

export const listItemAnimationVariant = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};
