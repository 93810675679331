/** @jsx jsx */
import { jsx, Flex, Text } from 'theme-ui';
import InnerHTML from 'dangerously-set-html-content';

import { BannerProps } from './types';

const Banner: React.FC<BannerProps> = ({ adCode }) => (
  <Flex variant="banner">
    <Text variant="typo1" color="grayChateau">
      {adCode ? <InnerHTML html={adCode} /> : '(banner ad space)'}
    </Text>
  </Flex>
);

export default Banner;
