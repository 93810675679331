import { BriefListItem } from 'components/organisms/BriefList/types';
import { HeroProps } from 'components/organisms/Hero/types';
import shuffle from 'lodash/shuffle';
import {
  customPresetItem,
  MAX_ITEMS_DISPLAYED,
  SelectPresetItem,
} from './consts';
import {
  ICategory,
  ICategoryItem,
  IBriefSectionHeading,
  IBanner,
  IPreset,
} from './types';

export const numberWithCommas = (x: number): string =>
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const getRandomList = (
  categories: ICategory[],
  count = 5,
): ICategory[] => shuffle(categories).slice(0, count);

export function getRandomElement<T>(list: T[]): T {
  return shuffle(list)[0];
}

export const getItemParameters = (
  id: string,
  items: ICategoryItem[],
): string[] => items.find((item) => item.id === id)?.items;

const selectedIds = (arr: ICategory[]): string[] => arr.map(({ id }) => id);

export const buildCategoriesItems = (
  categories: ICategory[],
): ICategoryItem[] => {
  const selectedCategoriesIds = selectedIds(getRandomList(categories));

  return categories.map(({ parametersList, ...category }) => ({
    ...category,
    items: shuffle(parametersList),
    value: category.name,
    label: category.name,
    selected: selectedCategoriesIds.includes(category.id),
    otherPossibilitiesCount:
      parametersList.length > MAX_ITEMS_DISPLAYED
        ? parametersList.length - MAX_ITEMS_DISPLAYED
        : null,
    disabled: false,
  }));
};

export const getPossibilitiesCount = (
  categories: (ICategory | ICategoryItem)[],
): number =>
  categories.reduce(
    (result, category) =>
      result *
      ('parametersList' in category ? category.parametersList : category?.items)
        .length,
    1,
  );

export const getBriefItems = (
  categories: ICategoryItem[],
  addCategory = true,
  randomizeItems = false,
): BriefListItem[] =>
  categories.map(
    ({
      id,
      name,
      items,
      otherPossibilitiesCount,
      category = '',
      disabled,
    }) => ({
      id,
      label: name,
      category: addCategory ? getRandomElement(items) : category,
      disabled: disabled || false,
      otherPossibilitiesCount,
      items: randomizeItems ? shuffle(items) : items,
    }),
  );

export const getRandomizedBriefs = (
  items: BriefListItem[],
  activeCategories: ICategoryItem[],
): BriefListItem[] =>
  items.map(({ items: categoryItems, category, disabled, ...item }) => ({
    ...item,
    disabled,
    items: disabled ? categoryItems : shuffle(categoryItems),
    category: disabled
      ? category
      : getRandomElement(getItemParameters(item.id, activeCategories)),
  }));

export const getBriefSectionHeading = (
  isGeneratedBrief: boolean,
): IBriefSectionHeading => {
  if (!isGeneratedBrief) {
    return {
      heading: 'Select Categories',
      button: 'Generate your custom brief',
    };
  }
  return {
    heading: 'Your Brief',
    button: 'Generate another concept',
  };
};

export const checkStep1ForChanges = (
  briefItems: BriefListItem[],
  activeCategoriesIds: string[],
): boolean => {
  const hasAnyChanges =
    briefItems.length === activeCategoriesIds.length &&
    briefItems.every(
      ({ id }, index) => activeCategoriesIds.indexOf(id) === index,
    );

  return hasAnyChanges;
};

export const getHeroBanner = (banners: IBanner[]): HeroProps => {
  const banner = getRandomElement(banners);

  return {
    id: banner.id,
    title: banner?.title,
    subtitle: banner?.text,
    subtitleUrl: banner?.url,
    image: banner.image?.asset?.fluid,
    hotspot: banner.image?.hotspot,
  };
};
export const buildPresetItems = (presets: IPreset[]): SelectPresetItem[] => {
  const items: SelectPresetItem[] = presets.map(
    ({ name, categories, headerPicture }) => ({
      label: name,
      value: name,
      categories: categories.map(({ id }) => id),
      banner: getHeroBanner([headerPicture]),
    }),
  );

  return [customPresetItem, ...items];
};

export const swapItemsInArray = (
  items: string[],
  indexA: number,
  indexB: number,
): string[] => {
  const itemA = items[indexA];

  const clone = [...items];

  clone[indexA] = clone[indexB];
  clone[indexB] = itemA;

  return clone;
};

export const generateRandomInteger = (min: number, max: number): number =>
  Math.floor(min + Math.random() * (max + 1 - min));
