/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import ReactSelect from 'react-select';

import { IconArrows } from 'assets/svg';
import FieldWrapper from '../FieldWrapper';
import { SelectProps } from './types';
import useCustomStyles from './hooks';
import { FieldSize } from '../types';

const Select: React.FC<SelectProps> = ({
  label,
  size = FieldSize.default,
  ...props
}) => {
  const customStyles = useCustomStyles(size);

  return (
    <FieldWrapper
      {...{ label, size }}
      controller={
        <ReactSelect
          styles={customStyles}
          isSearchable={false}
          isClearable={false}
          maxMenuHeight={200}
          components={{
            IndicatorsContainer: () => (
              <Box variant="forms.select.indicators">
                <IconArrows />
              </Box>
            ),
          }}
          {...props}
        />
      }
    />
  );
};

export default Select;
