/* eslint-disable no-underscore-dangle */
import React, { useCallback, useMemo } from 'react';
import { Link } from 'theme-ui';
import { renderToStaticMarkup } from 'react-dom/server';
import BlockContent from '@sanity/block-content-to-react';

import Layout from 'components/Layout';
import CMS from 'components/sections/CMS';
import SEO from 'components/SEO';
import BriefContextProvider from '../../../Providers/BriefProvider';
import PresetsContextProvider from '../../../Providers/PresetsProvider';
import { IPageData, IToolButton } from './types';
import HomePageBody from '../homepage/HomePageBody';

const PageBody: React.FC<IPageData> = ({ data: { page } }) => {
  const ActionButton = useCallback(
    () =>
      page.buttonLink && page.buttonText ? (
        <Link
          variant="request"
          href={page.buttonLink}
          target="_blank"
          rel="noreferrer"
        >
          {page.buttonText}
        </Link>
      ) : null,
    [page],
  );

  const presetsButton = useMemo<IToolButton>(
    () => ({
      href: page.presetsButtonUrl,
      label: page.presetsButtonText,
    }),
    [page],
  );

  return (
    <Layout
      title={page.h1Text}
      description={page.descriptionText}
      subtitleText={page.subtitleText}
      subtitleUrl={page.subtitleUrl}
    >
      <SEO title={page.seoTitle} description={page.seoDescription} />
      {page.showBriefGenerator ? (
        <PresetsContextProvider>
          <BriefContextProvider>
            <HomePageBody adCode={page.adCode} presetsButton={presetsButton} />
          </BriefContextProvider>
        </PresetsContextProvider>
      ) : (
        <CMS
          title={page.title}
          content={renderToStaticMarkup(
            <BlockContent blocks={page._rawContent} />,
          )}
          action={<ActionButton />}
        />
      )}
    </Layout>
  );
};

export default PageBody;
