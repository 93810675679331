import { SelectItem } from 'components/atoms/Fields/Select/types';
import { HeroProps } from 'components/organisms/Hero/types';

export interface SelectPresetItem extends SelectItem {
  categories?: string[];
  banner?: HeroProps;
}

export const customPresetItem: SelectPresetItem = {
  value: 'Custom',
  label: 'Custom Preset',
};

export const MAX_ITEMS_DISPLAYED = 5;
