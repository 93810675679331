import styled from '@emotion/styled';
import { Text } from 'theme-ui';
import { WithTheme } from 'gatsby-plugin-theme-ui';

import media from 'setup/MediaQueries';

export const StyledText = styled(Text)<WithTheme>`
  @media ${media.smallPhone} {
    display: none;
  }
`;
