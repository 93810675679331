import React from 'react';
import { AspectRatio, Flex, Image as ThemedImage } from 'theme-ui';
import GatsbyImage from 'gatsby-image/withIEPolyfill';

import { ImagePropsType } from './types';

const Image: React.FC<ImagePropsType> = ({
  fluid,
  fixed,
  ratio,
  alt = '',
  src,
  objectPosition,
  ...gatsbyImageProps
}) => {
  const imageContent = (
    <Flex variant="image.wrapper">
      {src && <ThemedImage variant="image.media" {...{ src, alt }} />}
      {!src && fluid && !fixed && (
        <GatsbyImage
          loading="eager"
          fadeIn={false}
          {...{ alt, fluid, objectPosition, ...gatsbyImageProps }}
        />
      )}
      {!src && fixed && !fluid && (
        <GatsbyImage
          loading="eager"
          fadeIn={false}
          {...{ alt, fixed, objectPosition, ...gatsbyImageProps }}
        />
      )}
    </Flex>
  );

  return ratio ? (
    <AspectRatio {...{ ratio }}>{imageContent}</AspectRatio>
  ) : (
    imageContent
  );
};

export default Image;
