/** @jsx jsx */
import { jsx, Flex, Text, Box, Button } from 'theme-ui';
import { useContext } from 'react';

import { IconPlus } from 'assets/svg';
import { BriefContext } from '../../../Providers/BriefProvider/context';

const AddCategory: React.FC = () => {
  const { addCategory } = useContext(BriefContext);

  return (
    <Flex variant="addCategory.wrapper">
      <Button
        aria-label="Add Category"
        variant="addCategory.button"
        onClick={addCategory}
      />
      <Flex variant="addCategory.content">
        <Flex variant="addCategory.box">
          <Text as="p" variant="typo5" sx={{ variant: 'addCategory.title' }}>
            Add
            <br /> Category
          </Text>
          <Box variant="addCategory.icon">
            <IconPlus />
          </Box>
        </Flex>
        <Text as="p" variant="addCategory.info">
          eg. Mood, Scale, Character, etc.
        </Text>
      </Flex>
    </Flex>
  );
};

export default AddCategory;
