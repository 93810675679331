/** @jsx jsx */

import { Box, jsx, Label as ThemedLabel } from 'theme-ui';

import Label from 'components/atoms/Label';
import { FieldWrapperProps } from './types';

const FieldWrapper: React.FC<FieldWrapperProps> = ({ label, controller }) => (
  <ThemedLabel variant="forms.labelWrapper" as="label">
    {label && (
      <Box variant="forms.labelCustom">
        <Label as="span" {...{ label }} />
      </Box>
    )}
    {controller}
  </ThemedLabel>
);

export default FieldWrapper;
