/** @jsx jsx */
import { useCallback, useContext } from 'react';
import { jsx, Flex } from 'theme-ui';

import Select from 'components/atoms/Fields/Select';
import { FieldSize } from 'components/atoms/Fields/types';
import { SelectPresetItem } from 'components/pages/homepage/consts';
import { PresetProps } from './types';
import { StyledText } from './styles';
import { BriefContext } from '../../../Providers/BriefProvider/context';
import { PresetsContext } from '../../../Providers/PresetsProvider/context';

const Preset: React.FC<PresetProps> = ({ onChangePreset }) => {
  const { setNewCategories } = useContext(BriefContext);
  const { defaultValue, selectedValue, presets, setSelectedValue } = useContext(
    PresetsContext,
  );

  const handleOnChange = useCallback(
    (value: SelectPresetItem) => {
      setNewCategories(value.categories);
      if (value.banner) {
        onChangePreset(value.banner);
      }

      setSelectedValue(value);
    },
    [setNewCategories, onChangePreset, setSelectedValue],
  );

  return (
    <Flex variant="preset">
      <StyledText as="span" variant="preset.text">
        Preset
      </StyledText>
      <Select
        options={presets}
        size={FieldSize.medium}
        defaultValue={defaultValue}
        onChange={handleOnChange}
        value={selectedValue}
        selectedValue={selectedValue}
      />
    </Flex>
  );
};

export default Preset;
