export const demoParametersList: string[] = [
  'Tale of Two Cities',
  'Space Colony',
  'World War',
  'Corporate',
];

export const sentenceAnimationVariant = {
  hidden: {
    opacity: 1,
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.5,
      staggerChildren: 0.03,
    },
  },
};

export const letterAnimationVariant = {
  hidden: {
    opacity: 0,
    color: '#03A9F4',
    scale: 0.7,
    x: -2,
  },
  visible: {
    opacity: [0, 1, 1],
    color: ['#03A9F4', '#03A9F4', '#000'],
    scale: [1, 1, 1],
    x: [0, 0, 0],
  },
};

export const listAnimationVariant = {
  hidden: {
    opacity: 1,
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.5,
      staggerChildren: 0.1,
    },
  },
};

export const listItemAnimationVariant = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};
