/** @jsx jsx */
import { jsx, Flex, Box } from 'theme-ui';
import { Fragment } from 'react';

import Label from 'components/atoms/Label';
import Button from 'components/atoms/Buttons/Button';
import Banner from 'components/atoms/Banner';
import { ActionProps } from './types';

const Action: React.FC<ActionProps> = ({
  actionLabel,
  label,
  adCode,
  onGenerateBriefClick,
}) => (
  <Flex variant="action">
    <Flex variant="action.content">
      <Button aria-label={actionLabel} onClick={onGenerateBriefClick}>
        {actionLabel}
      </Button>
      {label && (
        <Box variant="action.label">
          <Label
            label={
              <Fragment>
                {label}{' '}
                <Box as="span" variant="action.desktopLabel">
                  possibilities
                </Box>
                <Box as="span" variant="action.mobileLabel">
                  total possible combinations
                </Box>
              </Fragment>
            }
          />
        </Box>
      )}
    </Flex>
    {adCode && (
      <Box variant="action.banner">
        <Banner {...{ adCode }} />
      </Box>
    )}
  </Flex>
);

export default Action;
