/** @jsx jsx */
import { jsx, IconButton as ThemedIconButton } from 'theme-ui';

import { IconButtonProps } from './types';

const IconButton: React.FC<IconButtonProps> = (props) => (
  <ThemedIconButton variant="icon" {...props} />
);

export default IconButton;
