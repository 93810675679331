import React, { Fragment, useContext, useMemo, useState } from 'react';
import ReactPlaceholder from 'react-placeholder/lib';
import { Flex } from 'theme-ui';
import uniqueId from 'lodash/uniqueId';

import Heading from 'components/atoms/Heading';
import Section from 'components/sections/Section';
import useHasMounted from 'hooks/useHasMounted';
import { PlaceholderSkeleton } from 'components/organisms/PlaceholderSkeleton';
import Action from 'components/molecules/Action';
import Hero from 'components/organisms/Hero';
import Tools from 'components/sections/Tools';
import BriefList from 'components/organisms/BriefList';
import { BriefContext } from '../../../Providers/BriefProvider/context';
import { getBriefSectionHeading } from './utils';
import useHomePageData from './hooks';
import { IHomePageBody } from './types';

const HomePageBody: React.FC<IHomePageBody> = ({ adCode, presetsButton }) => {
  const hasMounted = useHasMounted();
  const {
    possibilities,
    generateBrief,
    isGeneratedBrief,
    briefItems,
  } = useContext(BriefContext);
  const [animationIds, setAnimationIds] = useState<string[]>(
    Array.from(Array(5)).map(() => uniqueId()),
  );

  const briefSectionHeading = useMemo(
    () => getBriefSectionHeading(isGeneratedBrief),
    [isGeneratedBrief],
  );

  const { banner, setHeaderBanner, setRandomImage } = useHomePageData();

  return (
    <Fragment>
      <Hero {...banner} onClickRefresh={setRandomImage} />
      <Tools {...presetsButton} onChangePreset={setHeaderBanner} />
      <Section heading={<Heading title={briefSectionHeading.heading} />}>
        <ReactPlaceholder
          ready={hasMounted}
          customPlaceholder={<PlaceholderSkeleton />}
        >
          <BriefList {...{ animationIds, setAnimationIds }} />
        </ReactPlaceholder>
        <Flex>
          <Action
            actionLabel={briefSectionHeading.button}
            label={possibilities}
            onGenerateBriefClick={() => {
              generateBrief();
              setAnimationIds(
                briefItems.map(({ disabled }, index) =>
                  disabled ? animationIds[index] : uniqueId(),
                ),
              );
            }}
            {...{ adCode }}
          />
        </Flex>
      </Section>
    </Fragment>
  );
};

export default HomePageBody;
