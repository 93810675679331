/** @jsx jsx */
import { Fragment, useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { Box, jsx, Text } from 'theme-ui';
import Label from '../Label';

import { ParametersListProps } from './types';
import {
  letterAnimationVariant,
  listAnimationVariant,
  listItemAnimationVariant,
  sentenceAnimationVariant,
} from './consts';

const HEADING_LINE_HEIGHT = 42;

const ParametersList: React.FC<ParametersListProps> = ({
  items,
  itemsToShowCount = 5,
  otherPossibilitiesCount,
  onItemSelect,
  category,
}) => {
  const headingRef = useRef<HTMLParagraphElement | null>(null);
  const [headingLinesCount, setHeadingLinesCount] = useState(1);

  useEffect(() => {
    if (headingRef?.current) {
      const height = headingRef.current.clientHeight;
      setHeadingLinesCount(height / HEADING_LINE_HEIGHT);
    }
  }, [category, items]);

  return (
    <Fragment>
      {category && (
        <motion.p
          key={category || otherPossibilitiesCount}
          title={category}
          sx={{
            variant: 'parametersList.heading',
          }}
          variants={sentenceAnimationVariant}
          initial="hidden"
          animate="visible"
          layout
          ref={headingRef}
        >
          {category.split('').map((char, index) => {
            const isHyphen = char === '|';

            return (
              <motion.span
                // eslint-disable-next-line react/no-array-index-key
                key={`${char}-${index}`}
                variants={letterAnimationVariant}
                layout
                sx={{
                  variant: 'text.heading3',
                }}
              >
                {isHyphen ? '&shy;' : char}
              </motion.span>
            );
          })}
        </motion.p>
      )}
      {items.length ? (
        <Box variant="parametersList.wrapper">
          <motion.ul
            sx={{ variant: category ? 'parametersList' : undefined }}
            key={category || otherPossibilitiesCount}
            variants={listAnimationVariant}
            initial="hidden"
            animate="visible"
          >
            {items
              .slice(0, itemsToShowCount - headingLinesCount + 1)
              .map((item, itemIndex) => (
                <motion.li
                  sx={{ variant: 'parametersList.item' }}
                  key={item}
                  variants={listItemAnimationVariant}
                >
                  {onItemSelect ? (
                    <button
                      type="button"
                      onClick={() => onItemSelect(itemIndex, 'click')}
                      onMouseLeave={() => onItemSelect(itemIndex, 'mouseLeave')}
                      sx={{
                        variant: 'parametersList.button',
                      }}
                    >
                      <Text as="span" variant="body">
                        {item}
                      </Text>
                    </button>
                  ) : (
                    <Text as="p" variant="body">
                      {item}
                    </Text>
                  )}
                </motion.li>
              ))}
            {otherPossibilitiesCount && (
              <motion.li
                sx={{ variant: 'parametersList.item' }}
                key={otherPossibilitiesCount}
                variants={listItemAnimationVariant}
              >
                <Label
                  label={`+ ${
                    otherPossibilitiesCount +
                    (category ? headingLinesCount : headingLinesCount - 1)
                  } other possibilities`}
                />
              </motion.li>
            )}
          </motion.ul>
        </Box>
      ) : null}
    </Fragment>
  );
};

export default ParametersList;
