import { SelectPresetItem } from 'components/pages/homepage/consts';
import { createContext } from 'react';

type PresetsType = {
  defaultValue: SelectPresetItem;
  selectedValue: SelectPresetItem;
  presets: SelectPresetItem[];
  resetPreset: () => void;
  setSelectedValue: (item: SelectPresetItem) => void;
};

export const PresetsContext = createContext<PresetsType>({
  defaultValue: {} as SelectPresetItem,
  selectedValue: {} as SelectPresetItem,
  presets: [],
  resetPreset: () => null,
  setSelectedValue: (preset) => null,
});
