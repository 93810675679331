/* eslint-disable react/no-array-index-key */
/** @jsx jsx */
import { Fragment } from 'react';
import { jsx, Grid, Box } from 'theme-ui';
import { TextBlock, TextRow } from 'react-placeholder/lib/placeholders';
import 'react-placeholder/lib/reactPlaceholder.css';

export const PlaceholderSkeleton: React.FC = () => (
  <Fragment>
    <Grid as="ul" variant="briefList">
      {new Array(5).fill(0).map((_, idx) => (
        <Box
          as="li"
          key={idx}
          variant="briefList.parameters.desktopToolsWrapper"
        >
          <TextRow
            color="#707070"
            style={{ height: 36 }}
            sx={{ variant: 'placeholderSkeleton.loaderTextRow' }}
          />
          <TextBlock
            color="lightGray"
            rows={6}
            style={{ height: 213 }}
            sx={{ variant: 'placeholderSkeleton.loaderTextBlock' }}
          />
        </Box>
      ))}
    </Grid>
    <Grid as="ul" variant="briefList">
      {new Array(5).fill(0).map((_, idx) => (
        <Box
          as="li"
          key={idx}
          variant="briefList.parameters.mobileToolsWrapper"
        >
          <TextRow
            color="#707070"
            style={{ height: 20 }}
            sx={{ variant: 'placeholderSkeleton.loaderTextRow' }}
          />
          <TextBlock
            color="lightGray"
            rows={1}
            style={{ height: 36 }}
            sx={{ variant: 'placeholderSkeleton.loaderTextBlock' }}
          />
        </Box>
      ))}
    </Grid>
  </Fragment>
);
