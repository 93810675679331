/** @jsx jsx */
import { jsx, Text, Box, Container, Flex } from 'theme-ui';

import Inner from 'components/styles/Inner';
import { CMSProps } from './types';

const CMS: React.FC<CMSProps> = ({ title, action, content }) => (
  <Container variant="cms.wrapper">
    <Inner>
      <Flex variant="cms.heading">
        <Text as="h2" variant="heading2" sx={{ variant: 'cms.title' }}>
          {title}
        </Text>
        <Box variant="cms.action">{action}</Box>
      </Flex>
      <Box
        variant="cms.wysiwyg"
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    </Inner>
  </Container>
);

export default CMS;
