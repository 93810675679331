import React, { useContext } from 'react';
import { Box, Grid } from 'theme-ui';
import head from 'lodash/head';
import shuffle from 'lodash/shuffle';
import noop from 'lodash/noop';
import uniqueId from 'lodash/uniqueId';

import ParametersList from 'components/atoms/ParametersList';
import AddCategory from 'components/molecules/AddCategory';
import {
  generateRandomInteger,
  swapItemsInArray,
} from 'components/pages/homepage/utils';
import { MAX_ITEMS_DISPLAYED } from 'components/pages/homepage/consts';
import { BriefContext } from '../../../Providers/BriefProvider/context';
import { MAX_BRIEF_ITEMS_DISPLAYED } from '../../../Providers/BriefProvider';
import CategorySelect from './partials/CategorySelect';
import ParametersSelect from './partials/ParametersSelect';
import { BriefListProps, OnItemSelect } from './types';

const BriefList: React.FC<BriefListProps> = ({
  animationIds = [],
  setAnimationIds = noop,
}) => {
  const {
    briefItems,
    setActiveCategories,
    isGeneratedBrief,
    setBriefItems,
  } = useContext(BriefContext);

  const onItemMouseLeave = (
    id: string,
    columnItems: string[],
    itemIndex: number,
  ) => {
    if (columnItems.length <= MAX_ITEMS_DISPLAYED) return;

    const newItemIndex = generateRandomInteger(
      MAX_ITEMS_DISPLAYED,
      columnItems.length - 1,
    );
    const orderedItems = swapItemsInArray(columnItems, itemIndex, newItemIndex);

    if (isGeneratedBrief) {
      setBriefItems((prevItems) =>
        prevItems.map((item) =>
          item.id === id ? { ...item, items: orderedItems } : item,
        ),
      );
    } else {
      setActiveCategories((prevItems) =>
        prevItems.map((item) =>
          item.id === id ? { ...item, items: orderedItems } : item,
        ),
      );
    }
  };

  const onItemClick = (
    id: string,
    columnItems: string[],
    itemIndex: number,
  ) => {
    const category = columnItems[itemIndex];

    if (isGeneratedBrief) {
      setBriefItems((prevItems) =>
        prevItems.map((item) =>
          item.id === id
            ? { ...item, category, disabled: true, items: shuffle(item.items) }
            : item,
        ),
      );
    } else {
      setActiveCategories((prevItems) =>
        prevItems.map((item) =>
          item.id === id
            ? { ...item, category, disabled: true, items: shuffle(item.items) }
            : item,
        ),
      );
    }
  };

  const onItemSelect: OnItemSelect = (eventType, ...rest) => {
    if (eventType === 'click') {
      onItemClick(...rest);

      return;
    }

    onItemMouseLeave(...rest);
  };

  return (
    <Grid as="ul" variant="briefList">
      {briefItems.map(
        (
          {
            id,
            items: columnItems,
            otherPossibilitiesCount,
            category,
            disabled,
          },
          index,
        ) => (
          <Box key={id} as="li">
            <Box variant="briefList.box">
              {category ? (
                <ParametersSelect
                  {...{ id, disabled }}
                  onRandomizeClick={() => {
                    setAnimationIds((currentAnimationIds: string[]) => {
                      const newItems = currentAnimationIds.filter(
                        (_, animationIdIndex) => animationIdIndex !== index,
                      );
                      newItems.splice(index, 0, uniqueId());

                      return newItems;
                    });
                  }}
                />
              ) : (
                <CategorySelect
                  {...{ id }}
                  categoryExample={
                    columnItems.length > 0 ? head(columnItems) : undefined
                  }
                />
              )}
              <ParametersList
                {...{ category, otherPossibilitiesCount }}
                key={animationIds[index] || category}
                items={columnItems}
                itemsToShowCount={category ? 5 : 6}
                onItemSelect={(itemIndex, eventType) =>
                  onItemSelect(eventType, id, columnItems, itemIndex)
                }
              />
            </Box>
          </Box>
        ),
      )}
      {briefItems.length < MAX_BRIEF_ITEMS_DISPLAYED && (
        <Box as="li">
          <AddCategory />
        </Box>
      )}
    </Grid>
  );
};

export default BriefList;
