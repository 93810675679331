/** @jsx jsx */
import { jsx, Text } from 'theme-ui';

import { HeadingProps } from './types';

const Heading: React.FC<HeadingProps> = ({ title }) => (
  <Text as="h3" variant="heading2" sx={{ variant: 'heading.title' }}>
    {title}
  </Text>
);

export default Heading;
