import { useBreakpointIndex } from '@theme-ui/match-media';
import { useMemo } from 'react';
import { Styles } from 'react-select';

import useTheme from 'hooks/useTheme';
import { FieldSize } from '../types';

const useCustomStyles = (size: FieldSize): Partial<Styles> => {
  const {
    transitions,
    forms: {
      sizes,
      select: {
        control,
        valueContainer,
        singleValue,
        placeholder,
        menu,
        option,
      },
    },
  } = useTheme();

  const breakpointIndex = useBreakpointIndex();

  const responsiveMenu = useMemo(
    () => ({
      ...menu,
      width: menu.sizes[size].width[breakpointIndex],
    }),
    [breakpointIndex, menu, size],
  );

  return useMemo(
    () => ({
      control: (provided) => ({
        ...provided,
        ...control,
        ...sizes[size],
      }),
      valueContainer: (provided) => ({
        ...provided,
        ...valueContainer,
      }),
      singleValue: (provided) => ({
        ...provided,
        ...singleValue,
        position: 'static',
      }),
      placeholder: (provided) => ({
        ...provided,
        ...placeholder,
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      menu: (provided) => ({
        ...provided,
        ...responsiveMenu,
      }),
      menuList: (provided) => ({
        ...provided,
        padding: 0,
        '&::-webkit-scrollbar': {
          appearance: 'none',
          width: '0.5rem',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#B8B8B8',
          borderRadius: '0.25rem',
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: 'transparent',
        },
      }),
      option: (provided, { isSelected, isDisabled }) => ({
        ...provided,
        ...option,
        backgroundColor: isSelected ? 'rgb(159 168 173 / 0.4)' : 'transparent',
        color: isDisabled ? 'rgb(159 168 173 / 0.2)' : 'currentColor',
        transition: transitions.default.transition,
        pointerEvents: isDisabled ? 'none' : 'auto',
        '&:hover': {
          backgroundColor: isSelected
            ? undefined
            : ['', '', 'rgb(159 168 173 / 0.2)'],
        },
        '&:focus': {
          backgroundColor: 'rgb(159 168 173 / 0.2)',
        },
      }),
    }),
    [
      control,
      responsiveMenu,
      option,
      size,
      sizes,
      placeholder,
      transitions.default.transition,
      valueContainer,
      singleValue,
    ],
  );
};

export default useCustomStyles;
