import { createContext, Dispatch, SetStateAction } from 'react';
import { ICategoryItem } from 'components/pages/homepage/types';
import { BriefListItem } from 'components/organisms/BriefList/types';

type BriefType = {
  possibilities: string;
  categories: ICategoryItem[];
  activeCategories: ICategoryItem[];
  activeCategoriesIds: string[];
  briefItems: BriefListItem[];
  setNewCategories: (ids?: string[]) => void;
  setBriefItems: Dispatch<SetStateAction<BriefListItem[]>>;
  toggleActiveCategories: (oldId: string, newId: string) => void;
  toggleDisabled: (id: string) => void;
  removeCategory: (id: string) => void;
  addCategory: () => void;
  changeBriefItem: (oldId: string, newId: string) => void;
  reloadParameters: (categoryId: string) => void;
  generateBrief: () => void;
  setActiveCategories: Dispatch<SetStateAction<ICategoryItem[]>>;
  isGeneratedBrief: boolean;
};

export const BriefContext = createContext<BriefType>({
  possibilities: '',
  categories: [],
  activeCategories: [],
  activeCategoriesIds: [],
  briefItems: [],
  setNewCategories: (ids) => null,
  setBriefItems: (ids) => null,
  toggleActiveCategories: (oldId, newId) => null,
  toggleDisabled: (id) => null,
  removeCategory: (id) => null,
  addCategory: () => null,
  changeBriefItem: (oldId, newId) => null,
  reloadParameters: (categoryId) => null,
  generateBrief: () => null,
  setActiveCategories: () => null,
  isGeneratedBrief: false,
});
