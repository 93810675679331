import { useCallback, useEffect, useMemo, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { HeroProps } from 'components/organisms/Hero/types';
import { buildCategoriesItems, getHeroBanner, buildPresetItems } from './utils';
import { DataQuery, ICategoryItem } from './types';
import { SelectPresetItem } from './consts';

type IUseHomepageData = {
  categories: ICategoryItem[];
  banner: HeroProps;
  setHeaderBanner: (banner: HeroProps) => void;
  presets: SelectPresetItem[];
  setRandomImage: VoidFunction;
};

export const GET_DATA = graphql`
  query {
    categories: allSanityCategory {
      totalCount
      nodes {
        id
        name
        parametersList
      }
    }
    banners: allSanityHeaderPicture {
      nodes {
        id
        title
        text
        url
        image {
          asset {
            fluid(maxWidth: 1920) {
              ...GatsbySanityImageFluid_withWebp
            }
          }
          hotspot {
            x
            y
          }
        }
      }
    }
    presets: allSanityPreset {
      nodes {
        name
        id
        categories {
          id
          name
          parametersList
        }
        headerPicture {
          id
          title
          text
          url
          image {
            asset {
              fluid {
                src
              }
            }
            hotspot {
              x
              y
            }
          }
        }
      }
    }
  }
`;

const useHomePageData = (): IUseHomepageData => {
  const { categories, banners, presets }: DataQuery = useStaticQuery(GET_DATA);
  const categoriesList = useMemo(() => buildCategoriesItems(categories.nodes), [
    categories.nodes,
  ]);
  const presetsList = useMemo(() => buildPresetItems(presets.nodes), [
    presets.nodes,
  ]);

  const [headerBanner, setHeaderBanner] = useState<HeroProps>({} as HeroProps);

  const setRandomImage = useCallback(() => {
    setHeaderBanner((currentBanner) => {
      const bannersToReset = currentBanner.id
        ? banners.nodes.filter((banner) => banner.id !== currentBanner.id)
        : banners.nodes;

      return getHeroBanner(bannersToReset);
    });
  }, [banners.nodes]);

  useEffect(() => setRandomImage(), [setRandomImage]);

  return {
    categories: categoriesList,
    banner: headerBanner,
    setHeaderBanner,
    presets: presetsList,
    setRandomImage,
  };
};

export default useHomePageData;
