import React, { useCallback, useMemo, useState } from 'react';
import useHomePageData from 'components/pages/homepage/hooks';

import { PresetsContext } from './context';

const PresetsContextProvider: React.FC = ({ children }) => {
  const { presets } = useHomePageData();
  const defaultValue = useMemo(() => presets.slice(0, 1)[0], [presets]);
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  const resetPreset = useCallback(() => setSelectedValue(defaultValue), [
    defaultValue,
  ]);

  const context = useMemo(
    () => ({
      defaultValue,
      selectedValue,
      presets,
      resetPreset,
      setSelectedValue,
    }),
    [defaultValue, selectedValue, presets, resetPreset, setSelectedValue],
  );

  return (
    <PresetsContext.Provider value={context}>
      {children}
    </PresetsContext.Provider>
  );
};

export default PresetsContextProvider;
