/** @jsx jsx */
import { Link } from 'gatsby';
import { jsx, Box, Text, Flex, Link as ThemedLink, Button } from 'theme-ui';
import { useMemo } from 'react';

import routes from 'routes';
import Inner from 'components/styles/Inner';
import Image from 'components/atoms/Image';
import { IconQuestion, IconRandomizeCircle } from 'assets/svg';
import useHasMounted from 'hooks/useHasMounted';
import { HeroProps } from './types';

const Hero: React.FC<HeroProps> = ({
  image,
  title,
  subtitle,
  subtitleUrl,
  hotspot,
  onClickRefresh,
}) => {
  const hasMounted = useHasMounted();

  const objectPosition = useMemo(
    () => (hasMounted ? `${hotspot?.x * 100}% ${hotspot?.y * 100}%` : ''),
    [hasMounted, hotspot],
  );

  return (
    <Box variant="hero">
      <Box variant="hero.image">
        <Image fluid={image} {...{ objectPosition }} />
      </Box>
      <Inner>
        <Flex variant="hero.content">
          <Flex variant="hero.heading">
            <Text as="h1" variant="heading1" sx={{ variant: 'hero.title' }}>
              {title}
            </Text>
            <Flex variant="hero.tools">
              <Link
                to={routes.ABOUT}
                title="Use this app to help generate ideas for your artistic exercises or projects"
              >
                <IconQuestion />
              </Link>
              <Button variant="hero.tools.buttonLink" onClick={onClickRefresh}>
                <IconRandomizeCircle />
              </Button>
            </Flex>
          </Flex>
          {!!subtitle && (
            <ThemedLink
              href={subtitleUrl}
              target="_blank"
              rel="noreferrer"
              variant="hero.link"
            >
              {subtitle}
            </ThemedLink>
          )}
        </Flex>
      </Inner>
    </Box>
  );
};

export default Hero;
